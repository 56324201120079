<template>
    <div class="app-container" v-loading="loading">
        <el-header class="new-header">
            <div class="new-header-left">
                业务线路详情
                <el-button type="text" @click="goBack">返回</el-button>
            </div>
        </el-header>
        <div class="content-mian">
            <div class="block-title">
                <i class="el-icon-s-custom"></i>基本信息
                <el-button type="text" @click="dialogForUpdateBaseInfo = true">修改</el-button>
            </div>
            <div class="block">
                <el-table
                        :data="[form]"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column prop="businessLineId" label="ID" align="center"></el-table-column>
                    <el-table-column prop="businessLineName" label="业务线路名称" align="center"></el-table-column>
                    <el-table-column prop="stateName" label="状态" align="center"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                </el-table>
            </div>
            <div class="block-title">
                <i class="el-icon-s-opportunity"></i>关联应用
                <el-button type="text" @click="handleInitCreateBusinessLineApply">添加</el-button>
            </div>
            <div class="block">
                <el-table
                        :data="form.applyList"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column
                            prop="applyName"
                            label="应用名称"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="numberOfOperations"
                            label="操作次数"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="remark"
                            label="备注"
                            align="center"
                    ></el-table-column>
					<el-table-column
					        prop="middlePhoneCount"
					        label="中间号数量"
					        align="center"
					>
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" content="管理中间号">
								<div slot="reference" class="name-wrapper" @click="handleManagementMiddleNumber(scope.row)">
									<el-tag size="medium">{{scope.row.middlePhoneCount}}</el-tag>
								</div>
							</el-popover>
						</template>
					</el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-tooltip placement="top" content="编辑">
                                <el-button type="text" @click="handleInitUpdateBusinessLineApply(scope.row)"
                                           size="small">
                                    编辑
                                </el-button>
                            </el-tooltip>
                            <el-tooltip placement="top" content="删除">
                                <el-button type="text"
                                           style="color:red"
                                           @click="handleDeleteBusinessLineApply(scope.row)"
                                           size="small">
                                    删除
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="修改基本信息" :visible.sync="dialogForUpdateBaseInfo" @close="loadBusinessLineDetails">
            <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-width="150px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="业务线路名称:" prop="businessLineName">
                            <el-input
                                    v-model="form.businessLineName"
                                    placeholder="请输入业务线路名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="状态:" prop="state">
                            <el-select v-model="form.state" placeholder="状态">
                                <el-option label="启用" :value="1"></el-option>
                                <el-option label="停用" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注:" prop="remark">
                            <el-input
                                    v-model="form.remark"
                                    type="textarea"
                                    placeholder="请输入备注"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForUpdateBaseInfo = false"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleUpdateBusinessLine"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="新建业务线路应用" :visible.sync="dialogForCreateBusinessLineApply">
            <el-form
                    ref="createForm"
                    :model="createForm"
                    :rules="rules"
                    label-width="150px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="应用:" prop="applyId">
                            <el-select v-model="createForm.applyId" placeholder="应用" filterable>
                                <el-option v-for="item in applyList" :key="item.applyId" :label="item.applyName"
                                           :value="item.applyId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="操作次数:" prop="numberOfOperations">
                            <el-input-number v-model="createForm.numberOfOperations" controls-position="right" :min="0"/>
                        </el-form-item>
                        <el-form-item label="备注:" prop="remark">
                            <el-input
                                    v-model="createForm.remark"
                                    type="textarea"
                                    placeholder="请输入备注"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForCreateBusinessLineApply = false"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleCreateBusinessLineApply"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="修改业务线路应用" :visible.sync="dialogForUpdateBusinessLineApply">
            <el-form
                    ref="modifyForm"
                    :model="modifyForm"
                    :rules="rules"
                    label-width="150px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="应用:" prop="applyId">
                            <el-select v-model="modifyForm.applyId" placeholder="应用" filterable>
                                <el-option v-for="item in applyList" :key="item.applyId" :label="item.applyName"
                                           :value="item.applyId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="操作次数:" prop="numberOfOperations">
                            <el-input-number v-model="modifyForm.numberOfOperations" controls-position="right" :min="0"/>
                        </el-form-item>
                        <el-form-item label="备注:" prop="remark">
                            <el-input
                                    v-model="modifyForm.remark"
                                    type="textarea"
                                    placeholder="请输入备注"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForUpdateBusinessLineApply = false"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleUpdateBusinessLineApply"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
		<el-dialog title="" :visible.sync="dialogForManagementMiddleNumber" v-loading="loadingForManagementMiddle">
			<el-header class="new-header">
				<div class="new-header-left">中间号码管理</div>
				<div class="new-header-right">
					<el-button class="btn" @click="handleAddMiddleNumber" type="primary" plain>
						添加中间号
					</el-button>
				</div>
			</el-header>
			<el-table
			        :data="middlePhoneList"
			        border
			        style="width: 100%"
			        ref="list"
			        stripe
			        size="small"
			>
			    <el-table-column
			            prop="middleNumber"
			            label="中间号码"
			            align="center"
			    >
					<template slot-scope="scope">
					  <el-form :model="scope.row" :rules="middlePhoneRules" :ref="'scope'+scope.$index">
					    <el-form-item label-width="0px" prop="middleNumber" :rules="middlePhoneRules.middleNumber">
							<span v-if="scope.row.visible">{{scope.row.middleNumber}}</span>
							<el-input v-else v-model="scope.row.middleNumber" placeholder="请输入中间号码" clearable></el-input>
					    </el-form-item>
					  </el-form>
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="备注" align="center">
					<template slot-scope="scope">
						<el-form :model="scope.row" >
							<el-form-item label-width="0px" prop="remark">
								<span v-if="scope.row.visible">{{scope.row.remark}}</span>
								<el-input v-else type="textarea" v-model="scope.row.remark" placeholder="请输入备注" clearable></el-input>
							</el-form-item>
						</el-form>
					</template>
				</el-table-column>
			    <el-table-column label="操作" align="center">
			        <template slot-scope="scope">
						<el-tooltip placement="top" content="编辑">
							<el-button type="text" v-if="scope.row.visible" @click="handleEditMiddleVisible(scope.row)">编辑</el-button>
						</el-tooltip>
						<el-tooltip placement="top" content="保存">
							<el-button type="text" v-if="!scope.row.visible" @click="handleSaveMiddlePhone(scope.row)">保存</el-button>
						</el-tooltip>
			            <el-tooltip placement="top" content="移除">
							<el-button type="text" @click="handleDeleteMiddlePhone(scope.row)">移除</el-button>
			            </el-tooltip>
			        </template>
			    </el-table-column>
			</el-table>
			<div class="pagination-block">
				<el-pagination
						@size-change="middlePhoneHandleSizeChange"
						@current-change="middlePhoneHandleCurrentChange"
						:current-page="middlePhonePageVo.current"
						:page-sizes="[2, 10, 20, 50, 100]"
						:page-size="middlePhonePageVo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="middlePhoneTotalCount"
				>
				</el-pagination>
			</div>
		</el-dialog>
    </div>
</template>

<script>
    import {
        createBusinessLineApply,
        getApplyList,
        getBusinessLine,
        updateBusinessLine,
        updateBusinessLineApply,
        listByApplyRaIdByPage,
        addApplyMiddleNumber,
        updateApplyMiddleNumber,
        deleteApplyMiddleNumber, deleteBusinessLineApply
    } from "../../api";

    export default {
        data() {
            return {
                loading: false,
                businessLineId: this.$route.query.id,
                dialogForUpdateBaseInfo: false,
                dialogForCreateBusinessLineApply: false,
                dialogForUpdateBusinessLineApply: false,
				dialogForManagementMiddleNumber: false,
				loadingForManagementMiddle: false,
                adminId: this.$store.getters.adminId,
                form: {
                    businessLineId: '',
                    businessLineName: '',
                    remark: '',
                    state: '',
                    stateName: '',
                    updateTime: '',
                    applyList: []
                },
                modifyForm: {
                    applyRaId: null,
                    applyId: null,
                    remark: null,
                    numberOfOperations:null,
                    adminId: this.$store.getters.adminId,
                },
                createForm:{
                    applyId: null,
                    remark: null,
					businessLineId: this.$route.query.id,
                    adminId: this.$store.getters.adminId,
                    numberOfOperations:0,
                },
                rules: {
                    businessLineName: [{required: true, message: "请输入业务线路名称", trigger: "blur"}],
                    applyId: [{required: true, message: "请选择应用", trigger: "change"}],
                },
                applyList: [],
				middlePhoneList: [],
				middlePhonePageVo: {
				    current: 1,
				    size: 10,
				},
				middlePhoneTotalCount: 0,
				middlePhoneLoading: false,
				middlePhoneInfo: null,
				middlePhoneRules: {
				  middleNumber: [{required: true, message: '中间号码不能为空', trigger: 'blur'}],
				},
            };
        },
        created() {
            this.loadBusinessLineDetails()
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            handleDeleteBusinessLineApply(row){
                this.$confirm(`确认删除业务线路应用吗？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(()=>{
                     deleteBusinessLineApply({applyRaId:row.applyRaId,adminId:this.adminId}).then((data)=>{
                         if(data){
                             this.$message.success('删除成功')
                             this.loadBusinessLineDetails()
                         }
                     })
                }).catch((e)=>{
                    console.log(e)
                })
            },
            handleUpdateBusinessLineApply() {
                this.$refs.modifyForm.validate(async valid => {
                    if (valid) {
                        const data = await updateBusinessLineApply({...this.modifyForm})
                        if (data) {
                            this.$notify({title: '成功', message: '更新业务线路应用成功', type: 'success'})
							this.dialogForUpdateBusinessLineApply = false
                            await this.loadBusinessLineDetails()
                        }
                    }
                })
            },
            async handleInitUpdateBusinessLineApply(row) {
                this.modifyForm.applyId = row.applyId * 1;
                this.modifyForm.applyRaId = row.applyRaId * 1
                this.modifyForm.numberOfOperations = row.numberOfOperations * 1
                this.modifyForm.remark = row.remark
                if (!this.applyList.length) {
                    this.applyList = await getApplyList({adminId: this.adminId})
                    this.dialogForUpdateBusinessLineApply = true
                } else {
                    this.dialogForUpdateBusinessLineApply = true
                }
            },
            handleCreateBusinessLineApply(){
                this.$refs.createForm.validate(async valid => {
                    if (valid) {
                        const data = await createBusinessLineApply({...this.createForm})
                        if (data) {
                            this.$notify({title: '成功', message: '创建业务线路应用成功', type: 'success'})
                            this.dialogForCreateBusinessLineApply = false
                            await this.loadBusinessLineDetails()
                        }
                    }
                })
            },
            async handleInitCreateBusinessLineApply() {
                if (!this.applyList.length) {
                    this.applyList = await getApplyList({adminId: this.adminId})
                    this.dialogForCreateBusinessLineApply = true
                } else {
                    this.dialogForCreateBusinessLineApply = true
                }
            },
            handleUpdateBusinessLine() {
                this.$refs.form.validate(async valid => {
                    if (valid) {
                        const param = {
                            businessLineId: this.form.businessLineId,
                            businessLineName: this.form.businessLineName,
                            remark: this.form.remark,
                            state: this.form.state,
                            adminId: this.adminId,
                        }
                        const data = await updateBusinessLine(param)
                        if (data) {
                            this.$notify({title: '成功', message: '修改业务线路成功', type: 'success'})
                            this.dialogForUpdateBaseInfo = false
                            await this.loadBusinessLineDetails()
                        }
                    }
                })
            },
            async loadBusinessLineDetails() {
                this.loading = true
                const data = await getBusinessLine({businessLineId: this.businessLineId, adminId: this.adminId})
                if (data) {
                    Object.assign(this.form, data)
                    this.loading = false
                }
            },
			async handleManagementMiddleNumber(row) {
				this.middlePhoneInfo = row
				this.dialogForManagementMiddleNumber = true
				let params = {
				    ...this.middlePhonePageVo,
				    params: {
						applyRaId: row.applyRaId * 1,
						adminId: this.adminId * 1
					},
				};
				this.loadingForManagementMiddle = true
				await listByApplyRaIdByPage(params).then((data) => {
					const middlePhoneList = data.records;
					for (let i in middlePhoneList) {
						middlePhoneList[i].visible = '0'
					}
                    this.middlePhoneList = middlePhoneList;
                    this.middlePhoneTotalCount = data.total;
                    this.loadingForManagementMiddle = false;
                });
			},
			middlePhoneHandleSizeChange(val) {
			    this.middlePhonePageVo.size = val;
			    this.loadBusinessLineList();
			},
			middlePhoneHandleCurrentChange(val) {
			    this.middlePhonePageVo.current = val;
			    this.loadBusinessLineList();
			},
			handleAddMiddleNumber() {
				if (!this.middlePhoneInfo.applyRaId) {
					this.$message.error('选中信息为空');
				}
				let obj = {
				  middleNumberId: null,
				  applyRaId: this.middlePhoneInfo.applyRaId,
				  middleNumber: '',
				  remark: '',
				  visible: false
				}
				this.middlePhonePageVo.page = 1
				this.middlePhoneList.unshift(obj)
			},
			handleEditMiddleVisible(row) {
				row.visible = false
			},
			async handleSaveMiddlePhone(row) {
				this.loadingForManagementMiddle = true
				if (row.middleNumber.length <= 0) {
					this.$message.error('中间号码不能为空');
					this.loadingForManagementMiddle = false
				}
				row.adminId = this.adminId * 1
				if (!row.middleNumberId) {
					const data = await addApplyMiddleNumber(row)
					if (data) {
					    this.$notify({title: '成功', message: '保存成功', type: 'success'})
					    this.loadingForManagementMiddle = false
					    await this.handleManagementMiddleNumber(this.middlePhoneInfo)
					}
				} else {
					const data = await updateApplyMiddleNumber(row)
					if (data) {
						this.$notify({title: '成功', message: '保存成功', type: 'success'})
						this.loadingForManagementMiddle = false
						// await this.handleManagementMiddleNumber(this.middlePhoneInfo)
						row.visible = true
					}
				}
			},
			async handleDeleteMiddlePhone(row) {
				row.adminId = this.adminId * 1
				this.loadingForManagementMiddle = true
				if (!row.middleNumberId) {
					this.$notify({title: '成功', message: '删除成功', type: 'success'})
					this.loadingForManagementMiddle = false
					await this.handleManagementMiddleNumber(this.middlePhoneInfo)
				} else {
					const data = await deleteApplyMiddleNumber(row)
					if (data) {
						this.$notify({title: '成功', message: '删除成功', type: 'success'})
						this.loadingForManagementMiddle = false
						await this.handleManagementMiddleNumber(this.middlePhoneInfo)
					}
				}
			}
        },
    };
</script>

<style lang="scss" scoped>
    .content-mian {
        margin-top: 10px;

        .block-title {
            margin-top: 20px;
            font-weight: 500;
        }

        .block {
            margin-top: 10px;
        }
    }
</style>
